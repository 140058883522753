import React from 'react'
import styled from 'styled-components'
import { graphql, withPrefix } from 'gatsby'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  background: black;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  \* max-width: ${props => props.theme.sizes.maxWidth}; *\
`

const SocMedImage = styled.img`
  position: fixed;
  right: 2em;
  width: 50px;
  heigth: 50px;
  :hover {
    -webkit-transform: scale(1.5,1.5);
    -webkit-transition: all 0.3s ease-in-out;
  } 
  @media only screen and (max-width: 600px) {
    width: 30px;
    heigth: 30px;
  }
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ; 
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.base};
    }
  }
   margin-left: 1em;
   :first-child {
      position: relative;
      color: white;
      margin: 0;
      text-align: center;
      flex-basis: 100%;
  }
`

const Footer = () => (
  <Wrapper>
    <List>
    <Item>
       @2021 by Apapun Engrave. 
    </Item>
    </List>
    <a href="https://www.facebook.com/apapunengrave/" target="_blank">
        <SocMedImage style={{ bottom: '3em' }} src={withPrefix("fbIcon.png")}>
        </SocMedImage>
    </a>
    <a href="http://www.wasap.my/60162323670" target="_blank">
        <SocMedImage style={{ bottom: '7em' }} src={withPrefix("whatsappIcon.png")} >
        </SocMedImage>
    </a>
    <a href="https://www.instagram.com/apapunengrave/" target="_blank">
        <SocMedImage style={{ bottom: '11em' }} src={withPrefix("instaIcon.png")}>
        </SocMedImage>
    </a>      
    </Wrapper>
    
)

export default Footer
