import React, { useImperativeHandle, useRef } from 'react'
import { withPrefix, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import * as styles from './Menu.module.css'
import Helmet from 'react-helmet'

const Header = styled.header`
  background: black;
  width: 100%;
  padding: 1.5em 0;
  z-index: 100;
  .image-header {
    height: 65px;
    width: 100px;
  }
  .headerFont1 {
    font-size: 1.5em;
  }
  .headerFont2 {
   font-size: 1m;
   font-style:italic;
  }
`

const Span = styled.span`
   display: table-cell;
   vertical-align: middle;
`

const NavButton = styled(props => <div {...props}>{props.children}</div>)`
  position: fixed;
  top: 2em;
  right: 2em;
  width: 40px;
  height: 100px;
  span {
    border-bottom: '1px black';
    width: 40px;
  }
  @media screen and (min-width: 500px) {
      display: None;
  }
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
    }
    &:nth-child(2) {
      position: relative;
      color: white;
      display: table;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.base};
    &:hover {
      color: white;
      fontsize: 1.1em;
    }
  }

  @media screen and (max-width: 500px) {
    li:not(:first-child) {
      display: None;
    }
    ul {
    display: flex;
    justify-content: center;
    }
    .image-header {
    height: 65px;
    width: 100px;
    }
  }
`

const activeLinkStyle = {
  color: 'white',
  fontSize: '1.2em',
}

const activeLinkStyle2 = {
    color: 'white',
    fontSize: '2em',
}

const where = process.env.NODE_ENV === `production` ? '(Prod)' : '(Dev)'

class MenuApp extends React.Component {
  constructor(props) {
      super(props)
      console.log(styles);
    this.state = {
      open: false,
    }
  }

  render() {
    return (
      <div
        tabIndex="0"
        className={`${styles.menu} ${this.state.open ? `${styles.menuOpen}` : ''}`}
      >
        <span onClick={() => this.close()} role="button" style={{"color":"white" }}>
          X
        </span>
        <br />
        <ul>
          <li style={{"color":"white", "paddingTop":"10px" }}>
             <Link to="/work/" activeStyle={activeLinkStyle2} style={{"color":"white", "paddingTop":"10px" }}>
              Portfolio
            </Link>
          </li>
          <li style={{"color":"white", "paddingTop":"10px" }}>
            <Link to="/about/" activeStyle={activeLinkStyle2} style={{"color":"white", "paddingTop":"10px" }}>
              About
            </Link>
          </li>
          <li style={{"color":"white", "paddingTop":"10px" }}>
            <Link to="/blog/" activeStyle={activeLinkStyle2} style={{"color":"white", "paddingTop":"10px" }}>
              Blog
            </Link>
          </li>
          <li style={{"color":"white", "paddingTop":"10px" }}>
            <Link to="/contact/" activeStyle={activeLinkStyle2} style={{"color":"white", "paddingTop":"10px" }}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    )
  }

  close() {
    this.setState({ open: false })
  }

  open() {
      /*
     const url = typeof window !== 'undefined' ? window.location.href : '';
     navigate(
          url,
          {
              replace: false,
              state: { open: false }
          }
     )
     */
    this.setState({ open: true })
  }
}

const MenuAppRef = React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
  }))

  return <MenuApp ref={menuRef} {...props} />
})

class Menu extends React.Component {
  toggleMenu() {
    this.childMenu.open()
  }

  render() {
    return (
      <Header>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-33282DM9NM"></script>
                <script src={withPrefix('tracking.js')} type="text/javascript" />
            </Helmet>
        <Nav>
          <ul>
            <li>
              <Link to="/" activeStyle={activeLinkStyle}>
                <img src={withPrefix('logo.png')} className="image-header" />             
              </Link>
            </li>
            <li>
                <Span style={{"paddingLeft":"10px"}}>
                    <span className="headerFont1" >Apapun Engrave</span>
                    <br />
                    <span className="headerFont2" >Gives everything the power to express.</span>
                </Span>
            </li>
            <li>
              <Link to="/work/" activeStyle={activeLinkStyle}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="/blog/" activeStyle={activeLinkStyle}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/about/" activeStyle={activeLinkStyle}>
                About
              </Link>
            </li>
            <li>
              <Link to="/contact/" activeStyle={activeLinkStyle}>
                Contact
              </Link>
            </li>
          </ul>
        </Nav>
        <NavButton onClick={() => this.toggleMenu()} id="showHideButton">
          <hr />
          <hr />
          <hr />
        </NavButton>
        <MenuAppRef ref={el => (this.childMenu = el)} />
      </Header>
    )
  }
}

export default Menu
